import { render, staticRenderFns } from "./ProcurementDetail.vue?vue&type=template&id=8e4eb282&scoped=true"
import script from "./ProcurementDetail.vue?vue&type=script&lang=js"
export * from "./ProcurementDetail.vue?vue&type=script&lang=js"
import style0 from "./ProcurementDetail.vue?vue&type=style&index=0&id=8e4eb282&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e4eb282",
  null
  
)

export default component.exports