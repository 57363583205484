<template>
  <div class="body">
    <div class="banxin">
      <div v-if="!Aftersales" class="OrderDetail_top">
        <div class="tt df">
          <div class="suxian"></div>
          采购计划详情
        </div>
        <div class="steps df jfc">
          <div v-for="(item, index) in status===600?backsteps :status===400?rejectsteps: steps" :key="index" class="tou df aic">
            <div class="tep" style="text-align: center">
              <div style="font-size: 14px; color: #333; margin-bottom: 17px">
                {{ item.tep }}
              </div>
              <div style="margin-bottom: 17px" class="status">
                <img style="icon20" :src="index + 1 <= active ? item.status.T : item.status.F" alt />
              </div>
              <div class="time">
                <div style="
                    color: #707070;
                    line-height: 1.5;
                    width: 80px;
                    font-size: 12px;
                  " class="day">
                  {{ item.createTime }}
                </div>
              </div>
            </div>
            <div :style="
                index + 1 >= active + 1
                  ? 'background-image:linear-gradient(to right, #CDCDCD 0%, #CDCDCD 50%, transparent 50%);'
                  : ''
              " class="xuxian"></div>
          </div>
        </div>
        <div class="bolang"></div>
      </div>

      <div class="OrderDetail_mid">
        <div class="list">
          <div class="h1 aic df">
            <div style="margin-right: 9px" class="suxian"></div>
            采购计划信息
          </div>
          <div class="li df aic fs14">
            <div class="fhui">采购状态：</div>
            <div>{{ statusName }}</div>
          </div>
          <div class="li fs14">
            <div class="fhui">采购编号：</div>
            <div>{{ oderList.sn }}</div>
          </div>
          <!-- <div class="li fs14">
            <div class="fhui">支付编号：</div>
            <div>{{ oderList.order.paySn }}</div>
          </div>
          <div class="li fs14">
            <div class="fhui">订单来源：</div>
            <div class="nwp">
              {{ oderList.order.orderSource }}
              &nbsp;
            </div>
            <div v-if="
                oderList.order.orderSource &&
                oderList.order.orderSource.substr(0, 1) !== '自'
              " style="margin-left: 10px; width: 40px" @click="copywxtap(oderList.order.orderSource)" ref="copytext" class="cup gr">
              复制
            </div>
          </div>
          <div v-if="oderList.order.payTypeName" class="li fs14">
            <div class="fhui">支付方式：</div>
            <div>{{ oderList.order.payTypeName }}</div>
          </div> -->
          <div class="li fs14">
            <div class="fhui">下单时间：</div>
            <div>{{ oderList.createTime }}</div>
          </div>
        </div>
        <div class="su"></div>
        <div v-if="!Aftersales" class="seacend list">
          <div class="h1 df aic">
            <div style="margin-right: 9px" class="suxian"></div>
            收货信息
          </div>
          <div class="li fs14">
            <div class="fhui">客户：</div>
            <div>{{ oderList.customerName }}</div>
          </div>
          <div class="li fs14">
            <div class="fhui">收货方式：</div>
            <div>
              {{ oderList.receiveType == 1 ? "送货上门" : "到仓自提" }}
            </div>
          </div>
          <div class="li fs14">
            <div class="fhui">收货人：</div>
            <div>
              {{ oderList.takeName || oderList.receiveName }}
            </div>
          </div>
          <div class="li fs14">
            <div class="fhui">联系方式：</div>
            <div>
              {{ oderList.takePhone || oderList.receivePhone }}
            </div>
          </div>
          <div class="li fs14">
            <div class="fhui">收货地址：</div>
            <div class="fr">
              {{ oderList.receiveAddress }}
            </div>
          </div>
        </div>
      </div>
      <div class="OrderDetail_foot">
        <!-- <div
          v-if="130 <= status && status <= 160"
          style="padding: 18px 29px; background: #f9fafb"
          class="gong tanxin aic"
        >
          <div class="fhui fs14 df">
            物流公司：
            <span style="color: #333"
              >京东 DFGH23647835547244DH</span
            >
            <i
              @click="copywxtap('宁波 137988151312')"
              style="font-size: 12px; margin-left: 5px; color: #26c487"
              class="el-icon-document-copy cup"
            ></i>
            <div
              class="x"
              style="
                margin: 0 15px;
                width: 1px;
                height: 12px;
                opacity: 1;
                background: #e2e2e2;
              "
            ></div>
            <div class="gr cup fs14">物流详情</div>
          </div>

          <div class="biaoqian">
            <img
              v-if="status == 160"
              src="@/assets/images/details/yiwancheng.png"
              alt
            />
            <img
              v-if="130 <= status && status < 160"
              src="@/assets/images/details/daishouhuo.png"
              alt
            />
          </div>
        </div> -->
        <div class="list">
          <el-table :header-cell-style="{ background: '#fff' }" :data="oderList.productDetailDTOS" tooltip-effect="dark" style="width: 100%">
            <el-table-column label="商品" align="center" min-width="180">
              <template slot-scope="scope">
                <Detail-Card :rows="scope.row"> </Detail-Card>
              </template>
            </el-table-column>
            <el-table-column style="color: #333333" prop="spec" align="center" label="规格" width="auto"></el-table-column>
            <el-table-column style="color: #333333" prop="salesPrice" align="center" label="单价(元)" width="auto">
              <template>
                <div>
                  待定价
                </div>
              </template>
            </el-table-column>
            <el-table-column style="color: #333333" prop="productQuantity" align="center" label="数量" width="auto"></el-table-column>
            <!-- <el-table-column style="color: #333333" prop="ofsNum" align="center" label="出库数量" width="auto"></el-table-column>
            <el-table-column style="color: #333333" prop="ofsAmount" align="center" label="出库金额" width="auto"></el-table-column> -->
            <el-table-column style="color: #333333" width="100" align="center" label="总计(元)">
              <template slot-scope="scope">
                <div style="color: #333333">
                  待定价
                  <!-- {{
                    (scope.row.salesPrice * scope.row.productQuantity).toFixed(
                      2
                    )
                  }} -->
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="prodact">
          <div class="li">
            <div class="fs14">商品总数量：</div>
            <div class="fs14 tar">{{ oderList.sumProduct }}</div>
          </div>
          <div class="li">
            <div class="fs14">商品总金额：</div>
            <!-- <div class="fs14 tar">¥{{ oderList.sumPrice }}</div> -->
            <div class="fs14 tar">待定价</div>
          </div>
          <div class="li fred">
            <div class="fs14">应付总金额：</div>
            <div class="fs18 blod tar">
              待定价
            </div>
            <!-- <div class="fs18 blod tar">
              ¥{{oderList.paymentAmount}}
            </div> -->
          </div>

          <div v-if="status!==600 && status!==300 " class="li flex-end btn-box">
            <el-button @click="EditApprovalStatus" type="success">撤销</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
import DetailCard from "@/components/custom-base/custom-product-detail-card";
import emptyGoodsImg from "@/assets/images/empty-product.jpg";
import {
  mallEditApprovalStatus,
  myApprovalDetail,
  orderCancel,
} from "@/request/Orders/order";
export default {
  components: {
    DetailCard,
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, olVal) {
        if (newVal.params.id) {
          this.orderId = newVal.params.id;
          localStorage.setItem("orderDetailId", newVal.params.id);
        } else {
          this.orderId = localStorage.getItem("orderDetailId");
        }
      },
    },
  },
  created() {
    this.getList();
  },
  beforeDestroy() {
    // localStorage.removeItem("orderDetailId");
    clearInterval(this.timer);
  },
  mounted() {},
  data() {
    return {
      name: "",
      //寄回填写物流公司
      deliveryCompany: "",
      //寄回状态
      completeStatus: 0,
      emptyGoodsImg,
      orderId: "",
      statusName: "",
      status: 0, //审批状态：100待审批 200当前审批通过（待上级审批） 300上级审批通过（整体审批通过） 400 当前审批驳回 500 上级审批驳回
      oderList: {
        order: { endtime: "", payEndTime: null },
      },
      steps: [
        {
          createTime: "",
          tep: "生成采购计划",
          status: {
            T: require("@/assets/images/Procurement/s1-t.png"),
            F: require("@/assets/images/Procurement/s1-f.png"),
          },
        },
        {
          createTime: "",
          tep: "待审核",
          status: {
            T: require("@/assets/images/Procurement/s2-t.png"),
            F: require("@/assets/images/Procurement/s2-f.png"),
          },
        },
        {
          createTime: "",
          tep: "审核中",
          status: {
            T: require("@/assets/images/Procurement/s3-t.png"),
            F: require("@/assets/images/Procurement/s3-f.png"),
          },
        },
        {
          createTime: "",
          tep: "已完成",
          status: {
            T: require("@/assets/images/Procurement/s4-t.png"),
            F: require("@/assets/images/Procurement/s4-f.png"),
          },
        },
      ],
      rejectsteps: [
        {
          createTime: "",
          tep: "生成采购计划",
          status: {
            T: require("@/assets/images/Procurement/s1-t.png"),
            F: require("@/assets/images/Procurement/s1-f.png"),
          },
        },
        {
          createTime: "",
          tep: "待审核",
          status: {
            T: require("@/assets/images/Procurement/s2-t.png"),
            F: require("@/assets/images/Procurement/s2-f.png"),
          },
        },
        {
          createTime: "",
          tep: "审核中",
          status: {
            T: require("@/assets/images/Procurement/s3-t.png"),
            F: require("@/assets/images/Procurement/s3-f.png"),
          },
        },
        {
          createTime: "",
          tep: "已驳回",
          status: {
            T: require("@/assets/images/Procurement/s5-t.png"),
            F: require("@/assets/images/Procurement/s5-f.png"),
          },
        },
      ],
      //撤销
      backsteps: [
        {
          createTime: "",
          tep: "生成采购计划",
          status: {
            T: require("@/assets/images/Procurement/s1-t.png"),
            F: require("@/assets/images/Procurement/s1-f.png"),
          },
        },
        {
          createTime: "",
          tep: "待审核",
          status: {
            T: require("@/assets/images/Procurement/s2-t.png"),
            F: require("@/assets/images/Procurement/s2-f.png"),
          },
        },
        {
          createTime: "",
          tep: "已撤销",
          status: {
            T: require("@/assets/images/Procurement/s6-t.png"),
            F: require("@/assets/images/Procurement/s6-f.png"),
          },
        },
      ],
      active: 1,
      //售前
      Aftersales: null,
    };
  },
  methods: {
    //取消订单
    orderCancel(id) {
      this.$confirm("是否确认取消订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        orderCancel({ orderId: id }).then((res) => {
          if (res.code == 200) {
            this.$message.success("取消成功");
            this.$router.push("/user/myOrder");
          }
        });
      });
    },
    EditApprovalStatus() {
      let data = {
        approvalStatus: 600,
        sn: this.orderId,
      };
      this.$confirm("是否确认撤销审批?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        mallEditApprovalStatus(data).then((res) => {
          if (res.code == 200) {
            this.$message.success("撤销成功");
          }
          this.getList();
        });
      });
    },
    getList() {
      myApprovalDetail({ sn: this.orderId }).then((res) => {
        if (res.code === 200 && res.data != null) {
          this.oderList = res.data;
          this.status = res.data.status;
    
          // //完整流程
          this.steps[0].createTime=this.backsteps[0].createTime  = this.rejectsteps[0].createTime =
            this.oderList.createTime;
          this.steps[1].createTime = this.backsteps[1].createTime   = this.rejectsteps[1].createTime =
            this.oderList.approvalAwaitTime;
        


          this.backsteps[2].createTime = this.oderList.approvalRevocationTime;
          this.steps[3].createTime = this.oderList.approvalPassTime;
          this.rejectsteps[3].createTime = this.oderList.approvalRejectTime;

          // this.steps[2].createTime = this.oderList.order.paymentTime;
          // this.steps[3].createTime = this.oderList.approvalPassTime;
          //订单状态：-1全部 待付款-100 支付中-110 支付中-120 部分发货-130 待收货-140 部分收货-150 已完成-160 已关闭-170

          switch (res.data.status) {
            case 100:
              this.statusName = "待审批";
              this.active = 2;
              break;
            case 200:
              this.statusName = "待审中";
              this.active = 3;
              break;
            case 300:
              this.statusName = "已通过";
              this.active = 4;
              break;
            case 400:
              this.statusName = "已驳回";
              this.active = 4;
              break;
            case 500:
              this.statusName = "待审批";
              this.active = 2;
              break;
            case 600:
              this.statusName = "已撤销";
              this.active = 3;
              break;

            default:
              break;
          }
    if( this.active == 3|| this.active ==4){
       this.steps[2].createTime = this.rejectsteps[2].createTime =
            this.oderList.applyTime;
    }
        
        }
      });
    },
    showtime(endtime) {
      var nowtime = new Date(), //获取当前时间
        endtime = new Date(endtime); //定义结束时间
      var lefttime = endtime.getTime() - nowtime.getTime(), //距离结束时间的毫秒数
        leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)), //计算天数
        lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24), //计算小时数
        leftm = Math.floor((lefttime / (1000 * 60)) % 60), //计算分钟数
        lefts = Math.floor((lefttime / 1000) % 60); //计算秒数
      if (leftd > -1 && lefth > -1) {
        if (leftd > -1 && lefth > -1) {
          if (leftd >= 1) {
            return leftd + "天" + lefth + "时" + leftm + "分" + lefts + "秒"; //返回倒计时的字符串
          } else if (leftd <= 0 && lefth >= 1) {
            return lefth + "时" + leftm + "分" + lefts + "秒"; //返回倒计时的字符串
          } else {
            return leftm + "分" + lefts + "秒"; //返回倒计时的字符串
          }
        }
      }
    },
    // 复制
    copywxtap(txt) {
      let all = [];
      all.push(txt);
      this.copyContent = all.join(); //也可以直接写上等于你想要复制的内容
      var input = document.createElement("input"); // 直接构建input
      input.value = this.copyContent; // 设置内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功");
    },
  },
};
</script>
 
<style lang = "less" scoped>
@import "../../../assets/base.less";
::v-deep {
  .el-table__cell {
    color: #333333;
  }
  .el-button + .el-button,
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 20px;
  }
  .el-input__inner {
    width: 360px;
    height: 32px;
    line-height: 32px;
  }
  .el-step__title {
    font-size: 14px;
    color: #3333;
    position: absolute;
    top: -70%;
    left: 40%;
  }
  .el-step__title.is-process {
    font-size: 14px;
    color: #3333;
    font-weight: 400;
  }
}
.fr {
  line-height: 1.2;
  text-align: left;
  flex: 1;
}
.p32 {
  padding: 0px !important;
}
.time {
  height: 36px;
}
.xuxian {
  padding-bottom: 25px;
  width: 169px;
  height: 15px;
  background-image: linear-gradient(
    to right,
    @green 0%,
    @green 50%,
    transparent 50%
  );
  background-size: 23px 2px;
  background-repeat: repeat-x;
}
.xuxianF {
  width: 169px;
  height: 15px;
  background-image: linear-gradient(
    to right,
    #cdcdcd 0%,
    #cdcdcd 50%,
    transparent 50%
  );
  background-size: 23px 2px;
  background-repeat: repeat-x;
}
.tou:last-child {
  .xuxian {
    display: none;
  }
}
.OrderDetail-head {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  padding: 0 30px 30px 30px;
  margin-bottom: 20px;
}
.OrderDetail_top {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 30px;
  border-top: 2px solid @green;
  .bolang {
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 100%;
    height: 16px;
    background: #fff;
    background: url("../../../assets/images/details/reduis.png");
  }
}
.OrderDetail_mid {
  justify-content: space-around;
  display: flex;
  box-sizing: border-box;
  padding: 30px;
  width: 100%;
  background-color: #fff;
  .su {
    margin-top: 31px;
    border: 1px solid #f5f5f5;
    display: inline-block;
    vertical-align: middle;
    border-left: 1px solid #f5f5f5;
    content: "";
    height: 124px;
    line-height: 124px;
  }
  .list {
    width: 45%;
  }
  .seacend {
    margin-left: 80px;
  }
  .li {
    text-align: right;
    color: #333333;
    display: flex;
    margin-bottom: 16px;
    .fhui {
      min-width: 70px;
      max-width: 70px;
      color: #9e9fab;
      white-space: nowrap;
      margin-right: 10px;
    }
  }
  .h1 {
    font-size: 14px;
    font-family: 'medium';
    font-weight: 500;
    color: #333333;
    margin-bottom: 26px;
  }
}
.body {
  background-color: @body;
}
.prodact {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  padding: 20px 30px;
  text-align: right;
  color: #707070;
  background-color: #fff;
  .li {
    width: 220px;
    display: flex;
    margin-bottom: 20px;
    .fs14 {
      width: 52%;
      text-align: right;
    }
    .tar {
      flex: 1;
    }
  }
}
.OrderDetail_foot {
  margin-top: 30px;
}
.suxian {
  height: 16px;
}
.tt {
  font-size: 16px;
  font-family: 'medium';
  font-weight: 500;
  color: #333333;
  margin-bottom: 29px;
}
</style>